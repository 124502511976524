.default-page {
    background-color: #aaa;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prozirni-plast {
    height: 100%;
    width: 100%;
    background-color: RGBA(0,0,0,0.0);
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0px;
}

.prvi-page {
    background-color: #edc7a8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.drugi-page {
    background-color: #edc7a8;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.treci-page {
    background-color: #edc7a8;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;   
}

.sedmi-page {
    background-color: #baf5f3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.osmi-page {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.prvi-page-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.page30-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

@media screen and (min-width: 720px) {
    .prvi-page-slika {
        width: 85%;
        left: 7.5%;
    }

    .page30-slika {
        width: 85%;
        left: 7.5%;
    }
}

@media screen and (min-width: 1100px) {
    .prvi-page-slika {
        width: 65%;
        left: 17.5%;
    }

    .page30-slika {
        width: 40%;
        left: 25%;
    }
}

.prvi-page-el {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prvi-page-el-naslov {
    font-size: 35px;
    color: red;
    /*background-color: yellow;*/
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
}

.prvi-page-el-tekst {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
}

.drugi-page-slika {
    max-width: 100%;
    max-height: 70%;
    margin-top: 15px;
}

@media screen and (min-width: 720px) {
    .drugi-page-slika {
        max-width: 65%;
        max-height: 70%;
        left: 17.5%;
    }
}


.drugi-page-el-tekst {
    height: 20%;
    width: 70%;
    
    text-align: center;
}

.treci-page-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}



@media screen and (min-width: 720px) {
    .treci-page-slika {
        width: 85%;
        left: 7.5%;
    }
}

@media screen and (min-width: 1100px) {
    .treci-page-slika {
        width: 65%;
        left: 17.5%;
    }
}

.treci-page-el {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.treci-page-el-naslov {
    font-size: 35px;
    color: red;
    /*background-color: yellow;*/
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
}

.treci-page-el-tekst, .treci-page1-el-tekst {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
}

.treci-page-el-tekst1 {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
    font-size: 14px;
}

.treci-page-el-link {
    max-width: 15%;
    height: 20px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: red;
    color: white;
    margin-top: 30px;
    padding: 10px 13px 10px 13px;
    font-size: 12px;
    transition: background-color 0.6s, color 1.2s;
    border-radius: 3px;
}

.treci-page-el-link:hover {
    pointer: cursor;
    background-color: white;
    color: red;
}

.peti-page-el1 {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.peti-page-el1-link {
    height: 26px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    background-color: red;
    color: white;
    margin: 0px 20px 0px 20px;
    padding: 10px 13px 10px 13px;
    font-size: 12px;
    transition: background-color 0.6s, color 1.2s;
    border-radius: 3px;
}

.peti-page-el1-link:hover {
    cursor: pointer;
    background-color: white;
    color: red;
}

.page-scroll {
    overflow-y: auto;
}

.sesti-page-el {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sesti-page-dodatno {
    font-size: 17px;
    z-index: 2;
}

.sedmi-page-slika {
     max-width: 100%;
     max-height: 100%;
     position: static; 
}

.sedmi-page-dodatno {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
}

.osmi-page-el-naslov {
    font-size: 35px;
    color: #c27304;
    /*background-color: yellow;*/
    text-align: center;
    margin: 0px;
    padding: 30px 0px 0px 0px;
}

.osmi-page-el1-link {
    height: 26px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    background-color: red;
    color: white;
    margin: 0px 20px 0px 20px;
    padding: 10px 13px 10px 13px;
    font-size: 14px;
    transition: background-color 0.6s, color 1.2s;
    border-radius: 3px;
}

.osmi-page-el1-link:hover {
    pointer: cursor;
    background-color: white;
    color: red;
}

.deveti-page-el-tekst {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 15% 0px 0px 0px;
}

.deveti-page-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}



@media screen and (min-width: 720px) {
    .deveti-page-slika {
        width: 85%;
        left: 7.5%;
    }
}

@media screen and (min-width: 1100px) {
    .deveti-page-slika {
        width: 45%;
        left: 27.5%;
    }
    
    .deveti-page-el-tekst {
        width: 50%;
        padding-top: 10%;
    }
}

.deseti-page-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

@media screen and (min-width: 950px) {
    .deseti-page-slika {
        width: 75%;
        left: 12.5%;
    }
    
}

@media screen and (min-width: 1300px) {
    .deseti-page-slika {
        width: 55%;
        left: 22.5%;
    }
    
}

.page11-slika {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

@media screen and (min-width: 660px) {
    .page11-slika {
        width: 75%;
        left: 12.5%;
    }
    
}

@media screen and (min-width: 900px) {
    .page11-slika {
        width: 55%;
        left: 22.5%;
    }   
}

@media screen and (min-width: 1300px) {
    .page11-slika {
        width: 35%;
        left: 32.5%;
    }   
}

.page12-slika {
    width: 100%;
}

@media screen and (min-width: 660px) {
    .page12-slika {
        width: 75%;
        left: 12.5%;
    }
    
}

@media screen and (min-width: 900px) {
    .page12-slika {
        width: 55%;
        left: 22.5%;
    }   
}

@media screen and (min-width: 1300px) {
    .page12-slika {
        width: 35%;
        left: 32.5%;
    }   
}

.page12-el {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.page14-dodatno {
    margin-top: 20px;
}

.page14-dodatno:hover {
    background-color: green;
}

.page27-dodatno {
    margin-top: 20px;
    font-size: 20px;
}

.page27-dodatno:hover {
    background-color: green;
}

.page15-dodatno {
    background-color: #e8c394;
}

.page17 {
    background-color: #6fb9ed;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.page18 {
    background-color: #d3f2e7;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: relative;  */
}

.page18-el {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    /*transition-delay: 1s;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.page18-viewpoint {
    height: 0%;
    width: 0%;
    position: absolute;
    top: 49.5%;
    left: 49.5%;
    background-color: rgba(0,0,0,0);
    z-index: 5;
    pointer-events: none;
}

.page19 {
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.page12-el-tekst {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 15% 0px 0px 0px;
}

.page21 {
    background-color: #eee099;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (hover: none) {
    #hoverTest {
        background-color: #c4fbe0;
    }
}

.page21-slika {
    width: 330px;
    height: 186px;
    position: relative;
    /*background-color: red;*/
}

@media screen and (min-width: 450px) {
    .page21-slika {
        width: 400px;
        height: 262px;
    }
}

@media screen and (min-width: 650px) {
    .page21-slika {
        width: 600px;
        height: 390px;
    }
}

@media screen and (min-width: 850px) {
    .page21-slika {
        width: 800px;
        height: 452px;
    }
}

.page21-slika-slika {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.page22 {
    background-color: #859973;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.page22-el, .page22-el-cont {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    /*top: 90%;*/
    left: 0px;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    /*transition-delay: 1s;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page22-video {
    /*height: 100px;
    width: 200px;*/
    max-width: 80%;
    max-height: 80%;
}

.page22-gumb {
    height: 40px;
    width: 130px;
    background-color: red;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    transition: background-color 0.6s, color 1.2s;
}

.page22-gumb:hover {
    background-color: white;
    color: red;
    cursor: pointer;
}

.page22-el-tekst-dodatno {
    font-size: 16px;
}

.page23-el-tekst-dodatno {
    font-size: 15px;
}

.page22-el-button { 
    position: absolute;
    top: 2%;
    right: 10%;
    /*background-color: pink;*/
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    transition: color 0.5s;
}

.page22-el-button-ikona {
    height: 60%;
    width: 60%;  
}

.page22-el-button-ikona1 {
    height: 70%;
    width: 70%;  
}

.page22-el-button:hover {
    color: black;
    cursor: pointer;
}

.page24 {
    background-color: #ffc0cb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.page24-slika {
    width: 80%;
}

.page26-slika {
    max-width: 100%;
    max-height: 100%;
}

.page26 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page29 {
    background-color: #edc7a8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page36 {
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    height: 200%;
}

.page36-el {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page36-el1 {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    opacity: 0.0;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    overflow: auto;
}

.page36-el-tekst {
    width: 80%;
    text-align: center;
    margin: 0px;
    padding: 20px 0px 20px 0px; 
}

.page29-el {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 660px) {
    .page24-slika {
        width: 45%;
    }
    
}

@media screen and (min-width: 900px) {
    .page24-slika {
        width: 35%;
    }   
}

@media screen and (min-width: 1300px) {
    .page24-slika {
        width: 28%;
        left: 32.5%;
    }   
}

.page25 {
    background-image: url("portfolioSite.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


/*@media screen and (max-width: 400px) {*/
@media (hover: none) {
    .treci-page-el-tekst {
        font-size: 14px;
    }

    .treci-page1-el-tekst {
        font-size: 13px;
    }
    
    .treci-page-el-naslov {
        font-size: 28px;
        color: red;
        /*background-color: yellow;*/
        text-align: center;
        margin: 0px;
        padding: 20px 0px 0px 0px;
    }
    
    .treci-page-el-link { font-size: 11px; }
    
    .treci-page-el-tekst1 {
        padding: 20px 0px 0px 0px;
        font-size: 14px;
    }
    
    .deveti-page-el-tekst-dodatno { font-size: 14px; }
    .page22-el-tekst-dodatno { font-size: 13px; }
    .page23-el-tekst-dodatno { font-size: 13px; }
    .page19-el-tekst-dodatno { font-size: 14px; }
}
